<template>

        <router-view />
</template>

<script>
export default {
  name: "index",
  data(){
    return {

    }
  }
}
</script>

<style scoped>

</style>